export enum EAvailableFields {
  COURSES = "courses",
  MATTERS = "matters",
  BANKS = "banks",
  YEARS = "years",
  DIFFICULTIES = "difficulties",
  AMOUNT = "amount",
  TIME = "time",
  HIDE_ANSWERED = "hide_answered",
  AMOUNT_OPEN = "amount",
  SIMULATE_NAME = "name",
}

export type TFilterFormValues = {
  courses: Array<string>;
  matters: Array<string>;
  banks: Array<string>;
  years: Array<string>;
  difficulties: Array<string>;
  amount: string;
  time: string;
  hide_answered: boolean;
  name?: string;
};

export type TQuestionFilterProps = {
  fieldToShow: Array<EAvailableFields>;
  requiredFields?: Array<EAvailableFields>;
  isFlashCard?: boolean;
  isSimulate?: boolean;
  onSubmit: (data: TFilterFormValues) => void;
  isLoadingFilter?: boolean;
  showNotFound?: boolean;
  amountAvailable?: Array<string>;
};

export type TFilterOptions = {
  banks: Array<string>;
  courses: {
    [matter: string]: Array<string>;
  };
  difficulties: Array<string>;
  years: Array<string>;
};
