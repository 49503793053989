import styled from "styled-components";

export const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 53px;
  height: 24px;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const Slider = styled.span<{ checked: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.checked ? "#3A66FB" : "#C9D0FD")};
  border-radius: 34px;
  transition: background-color 0.4s;

  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: ${(props) => (props.checked ? "#ffffff" : "#948CD8")};

  &::before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s;
    transform: ${(props) =>
      props.checked ? "translateX(27px)" : "translateX(0)"};
    top: 3px;
    left: 4px;
  }

  span {
    position: relative;
    z-index: 1;
    flex: 1;
    text-align: ${(props) => (props.checked ? "left" : "right")};
  }
`;
