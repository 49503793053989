import React from "react";

import { searchIcon, closeIcon } from "../../../assets";

import { TSearchInputProps } from "./types";
import * as S from "./styles";

export const SearchInput: React.FC<TSearchInputProps> = ({
  placeholder = "Buscar...",
  value,
  onChange,
  onClear,
}) => {
  return (
    <S.SearchInputContainer>
      <img src={searchIcon} height={12} />
      <S.StyledInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {value && (
        <div onClick={onClear} style={{ cursor: "pointer" }}>
          <img src={closeIcon} height={12} />
        </div>
      )}
    </S.SearchInputContainer>
  );
};
