import styled, { keyframes } from "styled-components";

export const CountdownContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
  z-index: 9999;
`;

const growAnimation = keyframes`
  0% {
    transform: scale(0.8) rotate(45deg);
  }
  100% {
    transform: scale(1) rotate(45deg); 
  }
`;

export const Diamond = styled.div`
  width: 250px;
  height: 250px;
  background-color: #6a5acd;
  border: 20px solid #f3f4ff;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 100px;
  font-weight: bold;
  transform: rotate(45deg);
  animation: ${growAnimation} 1s ease-in-out;

  @media (max-width: 550px) {
    width: 200px;
    height: 200px;
    font-size: 80px;
  }

  span {
    transform: rotate(-45deg);
  }
`;
