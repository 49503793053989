import "rsuite/Calendar/styles/index.css";
import pt from "rsuite/locales/pt_BR";

import { Calendar, CustomProvider } from "rsuite";

import { TRevisionCalendarProps } from "./types";
import * as S from "./styles";
import { useEffect } from "react";

export const RevisionCalendar = ({
  reviewDays,
  onChangeDate,
}: TRevisionCalendarProps) => {
  const renderCell = (date: Date) => {
    const today = new Date();

    const formattedDate = date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    const todayTimestamp = new Date(today.setHours(0, 0, 0, 0)).getTime();
    const dateTimestamp = new Date(date.setHours(0, 0, 0, 0)).getTime();

    if (reviewDays && reviewDays.includes(formattedDate)) {
      if (dateTimestamp < todayTimestamp) {
        return <S.Circle color="#FF8181" />;
      } else if (dateTimestamp === todayTimestamp) {
        return <S.Circle color="#7DC579" />;
      } else {
        return <S.Circle color="#948CD8" />;
      }
    }

    return null;
  };

  useEffect(() => {
    onChangeDate(new Date());
  }, []);

  return (
    <S.CalendarContainer>
      <CustomProvider locale={pt}>
        <Calendar
          compact
          renderCell={renderCell}
          onSelect={onChangeDate}
          style={{ width: "100%", maxWidth: "350px" }}
        />
      </CustomProvider>
    </S.CalendarContainer>
  );
};
