import React, { useEffect, useState } from "react";

import api, { END_POINTS } from "../../services/api";

import { useLocation } from "react-router-dom-v5-compat";
import { revisionsProps } from "../Revisions/interface";

import { Flex, PageTemplate, Question, ReviseLoading } from "../../components";
import { TQuestion } from "../../contexts/question";
import { capitalizeWords, scrollToTop } from "../../helpers";
import { ERoutesPath } from "../../routes";

export const RevisionQuestionsPage: React.FC = () => {
  const location = useLocation();

  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");

  useEffect(() => {
    scrollToTop();
    setSubject(location.state.area ? capitalizeWords(location.state.area) : "");

    const objectRevision = location.state as revisionsProps;
    handleGetSubjectsStudied(objectRevision);
  }, [location]);

  const handleGetSubjectsStudied = async (objectRevision: revisionsProps) => {
    try {
      setIsLoading(true);

      const questionsSubject = await api.get(
        `${END_POINTS.revisionByUser}/${objectRevision.id}`
      );
      setQuestions(questionsSubject.data);
    } finally {
      setIsLoading(false);
    }
  };

  const revisionBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Cronograma de revisões",
      route: ERoutesPath.REVISOES,
    },
    { label: "Revisão" },
  ];

  return (
    <PageTemplate
      title={`Revisão - ${subject}`}
      breadcrumbsItems={revisionBreadcrumb}
    >
      <Flex direction="column" width="100%" gap={16}>
        {isLoading ? (
          <ReviseLoading />
        ) : (
          <>
            {questions?.map((question, key) => (
              <Question data={question} key={question.id} number={key + 1} />
            ))}
          </>
        )}
      </Flex>
    </PageTemplate>
  );
};
