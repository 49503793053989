import { useEffect, useState } from "react";
import { Flex, Typography, Button } from "../../../components";

import * as S from "./styles";
import { clockCircleIcon, pauseIcon } from "../../../assets";
import { TTimerProps } from "./types";

export const Timer = ({
  time,
  enableFinish,
  onPause,
  onFinished,
}: TTimerProps) => {
  const [remainingTime, setRemainingTime] = useState(time);

  useEffect(() => {
    if (remainingTime <= 0) {
      onFinished();
      return;
    }

    const timerId = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [remainingTime, onFinished]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <S.TimerContainer>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={16}
      >
        <Flex gap={8}>
          <img src={clockCircleIcon} />
          <Typography
            size={16}
            color="#3A66FB"
            weight={500}
            align="left"
            style={{
              minWidth: "70px",
            }}
          >
            {formatTime(remainingTime)}
          </Typography>
        </Flex>

        <img
          src={pauseIcon}
          onClick={onPause}
          alt="Pausar"
          style={{ cursor: "pointer" }}
        />

        <Button size="small" disabled={!enableFinish} onClick={onFinished}>
          Finalizar
        </Button>
      </Flex>
    </S.TimerContainer>
  );
};
