import styled from "styled-components";
import { Flex } from "../../../components";

export const Container = styled(Flex)`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
`;

export const Link = styled.a`
  color: #3a66fb;
  text-decoration: underline;
`;
