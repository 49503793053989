import backgroundIdeia from "../../../assets/background/boxIdeia.png";
import backgroundCheck from "../../../assets/background/boxCheck.png";
import backgroundRevise from "../../../assets/background/boxRevise.png";
import backgroundTopic from "../../../assets/background/boxTopic.png";

export const faqData = [
  {
    id: 1,
    content:
      "Plataforma com mais de 1700 questões do CBR comentadas em que você mesmo pode filtrar por área, assunto, tipo de prova (R1, R2, R3 e USG), ano da aplicação e nível de dificuldade.",
    img: backgroundIdeia,
  },
  {
    id: 2,
    content: `Com o Revise Rápido, você pode selecionar os assuntos que pretende estudar e resolver sentenças da prova do CBR com "verdadeiro ou falso?", revisando conceitos e estudando diretamente o que a prova cobra com discussões de cada alternativa. Forma didática, interativa e inovadora para melhorar sua experiência.`,
    img: backgroundRevise,
  },
  {
    id: 3,
    content:
      "Você ainda pode selecionar os assuntos já estudados ao longo do ano e nós selecionamos questões que vão servir como uma revisão contínua e baseada nos seus erros.",
    img: backgroundTopic,
  },
  {
    id: 4,
    content:
      "Com nossa plataforma, você estuda utilizando os principais métodos ativos. Tornando seu estudo mais eficiente e te deixando mais próximo dos seus objetivos!",
    img: backgroundCheck,
  },
];
