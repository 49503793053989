import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import {
  QuestionFilter,
  EditQuestion,
  PageTemplate,
  Flex,
  ReportQuestionModal,
  Countdown,
} from "../../components";
import {
  EAvailableFields,
  TFilterFormValues,
} from "../../components/organisms/QuestionFilter/types";
import api, { END_POINTS } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ERoutesPath } from "../../routes";

import { TFlashData } from "./types";

import {
  CardActions,
  FlashCardDescription,
  FlipCardContainer,
  ShowResult,
} from "./helpers";

export const FlashCardPage = () => {
  const FILTER_OPTIONS = [
    EAvailableFields.COURSES,
    EAvailableFields.MATTERS,
    EAvailableFields.BANKS,
    EAvailableFields.DIFFICULTIES,
    EAvailableFields.AMOUNT,
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<Array<TFlashData>>();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [answer, setAnswer] = useState<"A" | "B" | undefined>(undefined);
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [reportQuestion, setReportQuestion] = useState<boolean>(false);

  const navigate = useNavigate();
  const { isPaidActive, isUserLogged, user } = useContext(AuthContext);

  useEffect(() => {
    if (!isUserLogged || !isPaidActive) {
      navigate(ERoutesPath.ROOT);
    }
  }, []);

  const handleFilter = async (data: TFilterFormValues) => {
    let flashCardQuestions: Array<TFlashData> = [];
    try {
      setQuestions(undefined);
      setIsLoading(true);
      const flashCardQuestionsResponse = await api.post<Array<TFlashData>>(
        END_POINTS.flashcards,
        data
      );
      flashCardQuestions = flashCardQuestionsResponse.data;
      setShowCountdown(true);
    } catch (error) {
      navigate(ERoutesPath.ROOT);
    } finally {
      setTimeout(() => {
        setQuestions(flashCardQuestions);
        setShowCountdown(false);
      }, 3_000);
      setIsLoading(false);
    }
  };

  const handleResponse = (value: "A" | "B") => {
    if (questions) {
      const currentQuestion = questions[activeQuestion];
      const isCorrect = currentQuestion?.feedback === value;

      setCorrectAnswers((prev) => (isCorrect ? prev + 1 : prev));
    }
    setAnswer(value);
  };

  const hasMoreQuestion = useMemo(() => {
    if (questions) {
      return questions.length > activeQuestion + 1;
    }
  }, [questions, activeQuestion]);

  const handleNextQuestion = () => {
    setAnswer(undefined);

    if (hasMoreQuestion) setActiveQuestion(activeQuestion + 1);
    else setShowResult(true);
  };

  const cleanData = () => {
    setQuestions(undefined);
    setActiveQuestion(0);
    setAnswer(undefined);
    setCorrectAnswers(0);
    setShowResult(false);
  };

  const handleReport = () => {
    setReportQuestion(true);
  };

  const flashCardBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Revise rápido",
      route: ERoutesPath.REVISE_RAPIDO,
      onClick: () => cleanData(),
    },
  ];

  return (
    <PageTemplate title="Revise rápido" breadcrumbsItems={flashCardBreadcrumb}>
      {!questions?.length && (
        <Flex direction="column" gap={16}>
          <FlashCardDescription />

          <QuestionFilter
            fieldToShow={FILTER_OPTIONS}
            requiredFields={[EAvailableFields.AMOUNT]}
            onSubmit={handleFilter}
            isFlashCard
            showNotFound={questions && questions.length === 0}
            isLoadingFilter={isLoading}
          />
        </Flex>
      )}

      {showResult && (
        <ShowResult
          total={questions?.length}
          corrects={correctAnswers}
          onFinish={cleanData}
        />
      )}

      {!showResult && questions && questions.length > 0 && (
        <>
          <EditQuestion question={questions[activeQuestion]} />

          <Flex justifyContent="center">
            <Flex direction="column" gap={12}>
              <FlipCardContainer
                questionNumber={activeQuestion + 1}
                question={questions[activeQuestion]}
                userAnswer={answer}
                onHandleReport={handleReport}
              />

              {/* Acções */}
              <CardActions
                showNext={!!answer}
                onSelectAlternative={handleResponse}
                onClickNextQuestion={handleNextQuestion}
              />
            </Flex>
          </Flex>
        </>
      )}

      {reportQuestion && questions && (
        <ReportQuestionModal
          modalDismiss={() => {
            setReportQuestion(false);
          }}
          idQuestion={String(questions[activeQuestion].id)}
          idAccount={user.id_user}
        />
      )}

      {showCountdown && <Countdown />}
    </PageTemplate>
  );
};
