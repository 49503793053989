import Lottie from "lottie-react";

import animationData from "./animationData.json";

import * as S from "./styles";

export const ReviseLoading = () => {
  return (
    <S.LottieWrapper>
      <Lottie
        animationData={animationData}
        loop
        autoplay
        style={{ width: "100%", height: "100%" }}
      />
    </S.LottieWrapper>
  );
};
