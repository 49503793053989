import styled, { css } from "styled-components";
import { Flex } from "../../../components";

const colorModifiers = (isCorrect: boolean) => {
  if (isCorrect) {
    return css`
      background-color: #7dc579;
      color: #ffffff;
    `;
  }

  return css`
    background-color: #ff8181;
    color: #ffffff;
  `;
};

export const ChartGabaritoContainer = styled(Flex)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const GabaritoContainer = styled(Flex)`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  width: fit-content;
  flex-grow: 1;
`;

export const CircleContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

export const Circle = styled.div<{ isCorrect: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;

  ${({ isCorrect }) => colorModifiers(isCorrect)}
  &:hover {
    background-color: #f3f4fa;
    color: #958cd8;
  }
`;
