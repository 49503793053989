import styled from "styled-components";
import { Flex } from "../../../components";

export const ChartCounterContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  gap: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;

  width: 100%;
`;

export const CardContainer = styled.div`
  gap: 8px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;

  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 1 1 140px;
  }

  & > :last-child {
    flex: 0 1 210px;
  }
`;

export const ActionContainer = styled(Flex)`
  justify-content: unset;

  @media (min-width: 1050px) {
    justify-content: flex-end;
  }
`;
