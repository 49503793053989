import styled, { css } from "styled-components";
import { Flex } from "../../components";

export const ContentContainer = styled(Flex)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const NoRevisionContainer = styled(Flex)`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
`;

export const Question = styled(Flex)<{ isExpired?: boolean }>`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  ${({ isExpired = "#3a66fb" }) => css`
    border: 1px solid ${isExpired ? "#FF8181" : "transparent"};
  `}

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Actions = styled(Flex)`
  margin-left: auto;

  @media (max-width: 500px) {
    justify-content: space-between;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const CalendarContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;

  .rs-calendar-table-cell-is-today {
    .rs-calendar-table-cell-day {
      background-color: #735bf2;
      border-radius: 4px;
      margin-bottom: 2px;
    }
  }

  .rs-calendar-header-title {
    pointer-events: none;
  }
`;

export const Circle = styled.div<{ color: string }>`
  width: 6px;
  height: 6px;
  border: 2px solid ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;
