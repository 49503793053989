import styled from "styled-components";

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  gap: 8px;
  max-width: 400px;
  padding: 8px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #f4f2ff;
  box-sizing: border-box;

  &:focus-within {
    border-color: #9b87d5;
  }

  @media (max-width: 500px) {
    max-width: 170px;
  }
`;

export const StyledInput = styled.input`
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #6e6893;
  width: 100%;

  &::placeholder {
    color: #6e6893;
  }
`;
