import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";
import {
  Flex,
  PageTemplate,
  Question,
  Typography,
  ReviseLoading,
} from "../../../components";
import api, { END_POINTS } from "../../../services/api";
import { TQuestion } from "../../../contexts/question";
import { Chart } from "react-google-charts";
import * as S from "./styles";

export const SimulateResumePage = () => {
  const navigate = useNavigate();
  const { isPaidActive, isUserLogged, user } = useContext(AuthContext);

  const { id: simulateId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [correctErrors, setCorrectErrors] = useState<{
    correct: number;
    error: number;
  }>();

  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (!isUserLogged || !isPaidActive) {
      navigate(ERoutesPath.ROOT);
    }

    const handleInitialization = async () => {
      try {
        const simulateQuestions = await api.get<Array<TQuestion>>(
          `${END_POINTS.simulateQuestions}/${simulateId}`
        );

        const correctAnswers = simulateQuestions.data.filter(
          (question) => question.feedback === question.selected_answered
        ).length;
        const incorrectAnswers =
          simulateQuestions.data.length - (correctAnswers ?? 0);
        setCorrectErrors({ correct: correctAnswers, error: incorrectAnswers });

        setQuestions(Object.values(simulateQuestions.data));
      } finally {
        setIsLoading(false);
      }
    };

    handleInitialization();
  }, []);

  const scrollToQuestion = (index: number) => {
    const questionElement = questionRefs.current[index];
    if (questionElement) {
      const headerOffset = 100;
      const elementPosition = questionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const resumeTestBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Simulados",
      route: ERoutesPath.SIMULADOS,
    },
    {
      label: "Resumo",
    },
  ];

  const data = [
    ["Questões", "Qauntidade"],
    ["Acertos", correctErrors?.correct],
    ["Erros", correctErrors?.error],
  ];

  const options = {
    title: "Resultado",
    backgroundColor: "#FFFFFF",
    titleTextStyle: {
      color: "#6e6893",
      fontName: "Poppins",
      fontSize: 16,
      bold: false,
      alignment: "start",
    },
    chartArea: {
      left: 16,
      right: 16,
      top: 50,
      bottom: 50,
    },
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "#000000",
        fontSize: 12,
      },
    },
    colors: ["#7dc579", "#ff8181"],
  };

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          minHeight="500px"
          justifyContent="center"
          alignItems="center"
        >
          <ReviseLoading />
        </Flex>
      ) : (
        <PageTemplate title="Resumo" breadcrumbsItems={resumeTestBreadcrumb}>
          <Flex direction="column" gap={16}>
            <S.ChartGabaritoContainer gap={16}>
              <div
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                  minWidth: "300px",
                }}
              >
                <Chart
                  chartType="PieChart"
                  data={data}
                  options={options}
                  width={"100%"}
                  height={"250px"}
                  style={{ minWidth: "300px" }}
                />
              </div>

              <S.GabaritoContainer direction="column" gap={8}>
                <Typography color="#6e6893">Gabarito</Typography>
                <S.CircleContainer>
                  {questions &&
                    Object.values(questions).map((data, key) => {
                      const number = key + 1;
                      return (
                        <S.Circle
                          key={number}
                          isCorrect={data.feedback == data.selected_answered}
                          onClick={() => scrollToQuestion(number)}
                        >
                          {number}
                        </S.Circle>
                      );
                    })}
                </S.CircleContainer>
              </S.GabaritoContainer>
            </S.ChartGabaritoContainer>

            {questions &&
              questions.map((question, key) => (
                <Question
                  ref={(el) => (questionRefs.current[key + 1] = el)}
                  data={question as TQuestion}
                  key={question.id}
                  id={question.id}
                  number={key + 1}
                  data-id={question.id}
                  hideAction
                  previewsSelectedAnswered={question.selected_answered}
                  enableFeedback
                />
              ))}
          </Flex>
        </PageTemplate>
      )}
    </>
  );
};
