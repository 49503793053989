import { useState } from "react";

import { ModalContainer, Button, Flex } from "../../../components";

import api, { END_POINTS } from "../../../services/api";
import { TConfirmDeleteModalProps } from "./types";

export const ConfirmDeleteModal = ({
  simulateId,
  onCallback,
  modalDismiss,
}: TConfirmDeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);

    await api.delete(`${END_POINTS.simulates}/${simulateId}`);

    setIsLoading(false);
    onCallback?.();
    modalDismiss();
  };

  return (
    <ModalContainer
      modalDismiss={modalDismiss}
      title="Excluir simulado?"
      subtitle="Tem certeza de que deseja excluir este simulado? Essa ação é irreversível e não será possível refazê-lo."
    >
      <Flex gap={8} justifyContent="flex-end">
        <Button isSecondary size="small" onClick={modalDismiss}>
          Cancelar
        </Button>
        <Button size="small" onClick={handleDelete} isLoading={isLoading}>
          Confirmar
        </Button>
      </Flex>
    </ModalContainer>
  );
};
