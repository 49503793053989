import styled from "styled-components";

export const LottieWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
`;
