export enum ERoutesPath {
  ROOT = "/",
  INICIO_PROFISSIONAL = "/inicio-profissional",
  ADMINISTRAR_QUESTOES = "/administrar/questoes",

  QUESTOES = "/questoes",
  ASSUNTOS_ESTUDADOS = "/assuntos-estudados",
  USUARIOS = "/usuarios",
  REVISOES = "/revisoes",
  REVISAO = "/revisao",
  REVISAO_GERAL = "/revisao-geral",
  REVISE_RAPIDO = "/revise-rapido",
  CORRECAO = "/correcao",
  SIMULADOS = "/simulados",
  SIMULADOS_NOVO = "/simulados/novo",
  SIMULADO_RESPONDER = "/simulado/:id",
  SIMULADO_RESUMO = "/simulado/resumo/:id",
}
