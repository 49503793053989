import { differenceInDays, format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import { Typography, Flex, Button } from "../../components";
import { TQuestionRevision } from "./types";
import { capitalizeWords } from "../../helpers";
import { useNavigate } from "react-router-dom-v5-compat";
import { ERoutesPath } from "../../routes";

import { TRevisionCardsProps } from "./types";
import * as S from "./styles";

export const RevisionCards = ({ revisions }: TRevisionCardsProps) => {
  const navigate = useNavigate();

  const handleNavigation = (question: TQuestionRevision) => {
    if (question.difficulty === "Diversificado") {
      navigate(ERoutesPath.REVISAO_GERAL, { state: question });
    } else {
      navigate(ERoutesPath.REVISAO, { state: question });
    }
  };

  const renderAction = (qtdDays: number, question: TQuestionRevision) => {
    const { is_review } = question;

    if (qtdDays > 0 && !is_review) {
      return (
        <Typography size={12} color="#9C9B9B" weight={700}>
          Disponível em {qtdDays} dia(s)
        </Typography>
      );
    }

    if (qtdDays <= 0 && !is_review) {
      return (
        <Button size="small" onClick={() => handleNavigation(question)}>
          Revisar
        </Button>
      );
    }

    if (is_review) {
      return (
        <Typography size={12} color="#7DC579" weight={700}>
          Revisão finalizada
        </Typography>
      );
    }

    return null;
  };

  return (
    <Flex direction="column" gap={8}>
      {revisions.map((question: TQuestionRevision, i) => {
        const revisionDay = new Date(question.review_day);
        const currentDate = new Date();
        revisionDay.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const days = differenceInDays(revisionDay, currentDate) + 1;
        return (
          <S.Question
            key={question.id}
            isExpired={days < 0 && !question.is_review}
          >
            <Flex
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              gap={8}
            >
              <Typography weight={600}>
                Revisão - {capitalizeWords(question.area)} | Nível -{" "}
                {question.difficulty}
              </Typography>

              <Typography size={12} color="#8F9BB3">
                Data da revisão -{" "}
                {format(parseISO(question.review_day), "PPP", {
                  locale: ptBR,
                })}
              </Typography>
            </Flex>

            <S.Actions gap={4} alignItems="center">
              {renderAction(days, question)}
            </S.Actions>
          </S.Question>
        );
      })}
    </Flex>
  );
};
