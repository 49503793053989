import { useEffect, useState } from "react";
import * as S from "./styles";

export const Countdown = () => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1_000);
      return () => clearTimeout(timer);
    }
  }, [count]);

  return (
    <S.CountdownContainer>
      {count > 0 ? (
        <S.Diamond key={count}>
          <span>{count}</span>
        </S.Diamond>
      ) : null}
    </S.CountdownContainer>
  );
};
