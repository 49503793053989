import { useContext, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import api, { END_POINTS } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ERoutesPath } from "../../routes";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  Button,
  Flex,
  PageTemplate,
  Typography,
  ReviseLoading,
} from "../../components";
import { scrollToTop } from "../../helpers";
import { TQuestionRevision } from "./types";
import { RevisionCalendar } from "./Calendar";

import * as S from "./styles";
import { cautionIcon } from "../../assets";
import { RevisionCards } from "./Cards";

export const RevisionsPage = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataRevisions, setDataRevision] = useState<Array<TQuestionRevision>>(
    []
  );
  const [reviewDays, setReviewDays] = useState<Array<string>>();
  const [selectedDay, setSelectedDay] = useState<Date>();

  const idAccount = user.id_user;
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
    handleGetQuestions();
  }, []);

  const extractUniqueReviewDays = (
    data: Array<TQuestionRevision>
  ): Array<string> => {
    const reviewDays = data.map((item) => item.review_day);
    return Array.from(new Set(reviewDays));
  };

  const handleGetQuestions = async () => {
    try {
      setIsLoading(true);

      const revisionData = await api.get(
        `${END_POINTS.validateReview}${idAccount}`
      );
      setDataRevision(revisionData.data);
      setReviewDays(extractUniqueReviewDays(revisionData.data));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectStudies = () => {
    navigate(ERoutesPath.ASSUNTOS_ESTUDADOS);
  };

  const handleDate = (date: Date) => {
    setSelectedDay(date);
  };

  const filteredRevisions = useMemo((): Array<TQuestionRevision> | null => {
    if (selectedDay) {
      const filtered = dataRevisions.filter(
        (item) => selectedDay.toISOString().split("T")[0] === item.review_day
      );
      return filtered.length > 0 ? filtered : null;
    }
    return null;
  }, [selectedDay]);

  const filteredRevisionsOlder =
    useMemo((): Array<TQuestionRevision> | null => {
      const today = new Date();
      const filtered = dataRevisions.filter((item) => {
        const reviewDate = new Date(item.review_day);
        return reviewDate < today;
      });

      return filtered.length > 0 ? filtered.slice(0, 5) : null;
    }, [dataRevisions]);

  const revisionsBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Cronograma de revisões",
    },
  ];

  return (
    <PageTemplate
      title="Cronograma de revisões"
      breadcrumbsItems={revisionsBreadcrumb}
    >
      {isLoading ? (
        <ReviseLoading />
      ) : (
        <Flex width="100%" direction="column" gap={8}>
          <Button isSecondary onClick={handleRedirectStudies}>
            Marcar assuntos estudados
          </Button>

          <S.ContentContainer gap={16} width="100%">
            <RevisionCalendar
              reviewDays={reviewDays}
              onChangeDate={handleDate}
            />

            <Flex direction="column" style={{ flex: "1" }}>
              {selectedDay && (
                <Typography size={12} color="#8F9BB3">
                  {format(selectedDay, "PPP", { locale: ptBR })}
                </Typography>
              )}

              {filteredRevisions && filteredRevisions.length > 0 ? (
                <RevisionCards revisions={filteredRevisions} />
              ) : (
                <Flex direction="column" gap={8}>
                  <S.NoRevisionContainer gap={8} alignItems="center">
                    <img src={cautionIcon} />
                    <Typography size={14}>
                      Não existe revisões para a data selecionada!
                    </Typography>
                  </S.NoRevisionContainer>

                  {filteredRevisionsOlder && (
                    <>
                      <Typography size={12} color="#8F9BB3">
                        Revisões pendentes
                      </Typography>

                      <RevisionCards revisions={filteredRevisionsOlder} />
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          </S.ContentContainer>
        </Flex>
      )}
    </PageTemplate>
  );
};
